import React, { useState } from 'react'
import classnames from 'classnames'
import CheckBox from './Share/CheckBox'
import Button from './Share/Button'
import GoogleTagManagerService from '../services/GoogleTagManagerService'
import Cookies from 'js-cookie'

const ContactForm = ({ formTarget }) => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [agb, setAgb] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false)
  const gtm = new GoogleTagManagerService()

  let responseMessage = 'Ihre Anfrage wurde erfolgreich verschickt!'
  if (!isSubmissionSuccessful) {
    responseMessage = 'Fehler beim Versenden. Bitte versuchen Sie es später noch einmal!'
  }
  const onSubmit = e => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const url = window.location.href
    const referrer = document.referrer
    const utmParams = {
      utm_source: Cookies.get('utm_source') || null,
      utm_medium: Cookies.get('utm_medium') || null,
      utm_campaign: Cookies.get('utm_campaign') || null,
      utm_content: Cookies.get('utm_content') || null,
      utm_term: Cookies.get('utm_term') || null,
      gclid: Cookies.get('gclid') || null,
      adgroupid: Cookies.get('adgroupid') || null,
    }
    const payload = { email, firstName, lastName, phone, message, url, ...utmParams, referrer }

    for (let [key, value] of formData.entries()) {
      payload[key] = value
    }
    setIsSubmitting(true)
    fetch(e.target.action, {
      method: 'POST',
      body: JSON.stringify(payload),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        setIsSubmitted(true)
        if (res.ok) {
          gtm.fireMQLEvent()
          setIsSubmissionSuccessful(true)
          setTimeout(() => {
            setIsSubmitted(false)
          }, 3000)
        } else {
          setIsSubmissionSuccessful(false)
        }
      })
      .catch(_ => {
        setIsSubmitted(true)
        setIsSubmissionSuccessful(false)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }
  const handleCheckBox = e => {
    setAgb(!agb)
  }

  return (
    <form method="POST" action={formTarget} onSubmit={onSubmit} className="w-full">
      <div className="mr-8 lg:w-3/4">
        <div className="mt-3 lg:flex lg:items-center">
          <input
            className="text-gray-900 leading-normal bg-gray-100 h-16 p-4 w-full lg:w-1/2  mr-2"
            type="text"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            placeholder="Vorname"
          />
          <input
            className="text-gray-900 leading-normal bg-gray-100 h-16 p-4 w-full lg:w-1/2 ml-2 "
            type="text"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            placeholder="Nachname"
          />
        </div>
        <div className="mt-3">
          <input
            className="text-gray-900 leading-normal bg-gray-100 h-16 p-4 w-full"
            value={email}
            type="email"
            required
            onChange={e => setEmail(e.target.value)}
            placeholder="Geschäftliche E-Mail-Adresse"
            title="Bitte geben Sie eine gültige E-Mail-Adresse an."
            pattern="^([a-zA-Z0-9\+_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
          />
        </div>
        <div className="mt-3">
          <input
            className="text-gray-900 leading-normal bg-gray-100 h-16 p-4 w-full"
            type="text"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            placeholder="Ihre Telefonnummer"
          />
        </div>

        <div className="mt-3">
          <textarea
            value={message}
            onChange={e => setMessage(e.target.value)}
            className="resize-none text-gray-900 h-64 leading-normal bg-gray-100 h-16 p-4 w-full"
            placeholder="Ihre Nachricht"
            rows=""
          />
        </div>
        <div className="w-full">
          <CheckBox
            labelFont={'.75rem'}
            Label={
              'Mit dem Absenden des Formulars akzeptiere ich die Verarbeitung meiner Daten gemäß der <a href="/datenschutz/" target="_blank" class="text-lendis-main" >Datenschutzerklärung.</a>'
            }
            Value="agb"
            Required={true}
            OnChange={e => handleCheckBox(e)}
          />
        </div>
        <div>
          <p
            className={`font-semibold ${classnames({
              'text-red-500': isSubmitted && isSubmissionSuccessful === false,
              'text-green-500': isSubmitted && isSubmissionSuccessful === true,
              hidden: !isSubmitted,
            })}`}
          >
            {responseMessage}
          </p>
        </div>
        <div className=" mt-4 text-center lg:text-left lg:my-4">
          <Button disabled={!agb || isSubmitting} green text="Jetzt anfragen" />
        </div>
      </div>
      <input type="hidden" name="form_id" value="contact_form" />
      <input type="hidden" name="form_name" value="Contact form" />
    </form>
  )
}

export default ContactForm
