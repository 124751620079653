import React from 'react'
import Layout from '../components/layout'
import GoogleMaps from '../components/GoogleMaps'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import ContactForm from '../components/ContactForm'
import Title from '../components/Share/Title'
import Header from '../components/Header'
import BreadCrumb from '../components/Share/BreadCrumb'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Kontakt',
  },
]

const ContactPage = () => {
  const heroImage =
    process.env.CLOUDINARY_URL +
    '/f_auto/website/10b_1920_darker_nastuh-abootalebi-284882-unsplash_m2o9zd.jpg'

  return (
    <Layout>
      <Header page="contact" />
      <Menu />
      <div
        className="big-background-image pb- bg-lendis-black bg-no-repeat bg-top lg:pb-40 px-3 pt-40"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">Kontakt</h1>

          <p className="font-semibold lg:text-2xl mb-10 mb-5 px-8 text-xl">
            So einfach erreichen Sie uns
          </p>
        </div>
        <div className="bg-white container mx-auto lg:px-10 pb-10">
          <BreadCrumb breadCrumbItems={breadcrumb} />
          <div className="px-10  ">
            <Title
              title="Jetzt unverbindlich anfragen"
              header="Jetzt anfragen"
              titleSize="text-3xl"
            />
          </div>

          <div className="bg-white px-10">
            <div className="mt-8 leading-loose  pb-8 lg:flex lg:items-center">
              <p className="mt-8 lg:w-3/4 lg:text-xl">
                Sie möchten flexibel Büroausstattung mieten oder sich zu unserem Software- und
                Service-Angebot beraten lassen? Dann rufen Sie uns einfach an oder schicken Sie uns
                eine Nachricht. Auf Anfrage schicken wir Ihnen gerne unseren Produktkatalog zu.
              </p>
            </div>
            <div className="lg:flex">
              <ContactForm formTarget={process.env.FORM_WEBHOOK_URL} />
              <div className="lg:mt-0 mt-6  lg:w-1/3">
                <div className="bg-gray-100 lg:mr-0 mr-8 py-12 pl-8">
                  <svg
                    width="22"
                    height="33"
                    viewBox="0 0 22 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25 11.7857C1.25 5.88437 5.69505 1.25 11 1.25C16.3049 1.25 20.75 5.88437 20.75 11.7857C20.75 13.6503 19.9464 16.2853 18.1403 19.7684C16.5183 22.8966 14.1437 26.6047 11 30.8989C7.8563 26.6047 5.48172 22.8966 3.85969 19.7684C2.05362 16.2853 1.25 13.6503 1.25 11.7857Z"
                      stroke="#222222"
                      strokeWidth="2.5"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 6.66667C9.15905 6.66667 7.66667 8.15905 7.66667 10C7.66667 11.8409 9.15905 13.3333 11 13.3333C12.8409 13.3333 14.3333 11.8409 14.3333 10C14.3333 8.15905 12.8409 6.66667 11 6.66667ZM6 10C6 7.23858 8.23858 5 11 5C13.7614 5 16 7.23858 16 10C16 12.7614 13.7614 15 11 15C8.23858 15 6 12.7614 6 10Z"
                      fill="#00AF54"
                    />
                  </svg>
                  <div className="mt-3">
                    <p className="uppercase">Unser Standort</p>
                    <p className="mt-3 text-gray-700">Oranienburger Straße 66,</p>
                    <p>10117 Berlin,</p>
                    <p> Deutschland</p>
                    <p className="mt-3 text-gray-700">Montag - Freitag</p>
                    <p className="text-gray-700">08:00 Uhr - 20:00 Uhr</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto w-full h-64">
          <GoogleMaps />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default ContactPage
