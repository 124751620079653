import React from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'

const mapStyles = {
  width: '100%',
  height: '327px',
}

const officeLocation = {
  latitude: 52.525063,
  longitude: 13.391687,
}

const GoogleMaps = ({ google }) => (
  <Map
    google={google}
    zoom={17}
    className={'map'}
    style={mapStyles}
    scaleControl={false}
    mapTypeControl={false}
    zoomControl={false}
    panControl={false}
    fullscreenControl={false}
    streetViewControl={false}
    initialCenter={{ lat: officeLocation.latitude, lng: officeLocation.longitude }}
  >
    <Marker position={{ lat: officeLocation.latitude, lng: officeLocation.longitude }} />
  </Map>
)

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDzGg1oxA9qkbgW_HfHnOXvw5NeAmjLaGE',
})(GoogleMaps)
